<template>
    <div>
        <el-dialog
                title="收货地址"
                :visible="dialogTableVisible"
                width="1000px"
                :append-to-body="true"
                :show-close="false"
        >
            <template slot="title">
                <span style="font-size:18px;font-weight:700">确认收款</span>
            </template>
            <div class="flex-between content" v-loading="loading">
                <div class="settlement">
                    <div class="text2">结算信息</div>
                    <div class="flex-between" style="padding-top:15px">
                        <span class="text1">商品总价: {{ calculateData.goodsOriginalPrice }}</span>
                        <span class="text1">优惠活动: {{ calculateData.activityName }}</span>
                    </div>
                    <div class="flex-between" style="padding-top:10px">
                        <span class="text1">商品价格: {{ calculateData.goodsPrice }}</span>
                        <span class="text1">优惠金额: {{ calculateData.discountPrice }}</span>
                    </div>
                    <div class="flex-between" style="padding-top:10px">
                        <span class="text1">制作物价格: {{ calculateData.productAmount }}</span>
                    </div>
                    <div class="flex-between" style="padding-top:10px">
                        <span class="text1">运费金额: {{ calculateData.totalFreight }}</span>
                    </div>
                    <div class="flex-between" style="padding-top:15px">
                        <span class="text1">实收金额: {{ calculateData.actualPayAmount }}</span>
                    </div>
                    <div style="display:flex;padding-top:14px">
                    <span class="remark">
                    备注:
                    </span>
                        <div style="flex:1">
                            <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model.trim="remark"
                                    maxlength="200"
                                    show-word-limit
                            >
                            </el-input>
                        </div>
                    </div>
                </div>

        <div class="mode-payment">
          <div v-show="!payWay">
            <div>
              <div class="text2" style="padding-bottom:10px">应收款</div>
              <div
                class="text3"
                style="padding-bottom:10px;border-bottom: 1px solid #e8e8e8;"
              >
                ¥ {{ calculateData.actualPayAmount }}
              </div>
              <div class="text1" style="padding-top:20px;padding-bottom:10px">
                支付方式
              </div>
              <div class="paymentView">
                <div
                  class="paymentItem listItem"
                  v-for="(item, index) in pay_ways"
                  :key="index"
                  @click="choosePayWay(item, index)"
                  :class="item.bool ? 'bgc' : 'border'"
                >
                  <div class="iconView">
                    <label class="label-icon">{{ item.ico }}</label>
                  </div>
                  <div class="textView overflowText">
                    <label class="label-name">{{ item.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-show="payWay" class="fadeInRight1">
            <div>
              <div style="text-align:right">
                <i class="el-icon-close close-icon" @click="closePayWay"></i>
              </div>
              <div
                id="qrCodePayPopView"
                class="popView"
                style="width: 480px"
                v-loading="qrCodePayView"
                :element-loading-text="qrCodePayViewName"
              >
                <div class="popView-contentView">
                  <div class="qrCodePayView flex-center">
                    <div class="contentView ">
                      <div class="amtView flex-center">
                        ¥
                        <span>{{ calculateData.actualPayAmount }}</span>
                      </div>
                      <div class="imgView flex-center">
                        <img src="https://www.shouqianba.com/img/logo.svg" />
                      </div>
                      <div class="qrCodeInputView flex-center">
                        <el-input
                          class="input-scanPayCode"
                          placeholder="请扫描或输入付款码"
                          autocomplete="off"
                          ref="pay"
                          v-model="payVal"
                          type="number"
                          autofocus="autofocus"
                        ></el-input>
                      </div>
                      <div class="tipView ">
                        <p class="flex-center">请使用支付宝扫码支付</p>
                        <p class="flex-center">
                          支付过程中，请不要关闭该二维码！
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="enter" :loading="buttonLoading"
          >收 款
        </el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
    <!--支付弹框-->
  </div>
</template>

<script>
import {
  // getGoodsStorePurchaseCalculate,
  addGoodsStorePurchase,
  payGoodsStorePurchase,
  getPayOrderQuery,
} from "@/api/commodity";

export default {
  props: {
    dialogTableVisible: {
      type: Boolean,
      default: false,
    },
    calculateData: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
    },
    purchaseId: {
      type: String,
    },
  },
  watch: {
    dialogTableVisible(value) {
      if (value) {
        // this.getGoodsStorePurchaseCalculateData();
        if (this.type == 2) {
          this.orderData = {
            purchaseOrderId: this.purchaseId,
          };
        } else {
          this.orderData = {};
        }
      } else {
        this.buttonLoading = false;
        this.orderData = {};
        this.payWay = null;
        this.payVal = null;
        this.qrCodePayView = false;
        this.qrCodePayViewName = "收款中...";
        this.pay_ways = [
          {
            name: "收钱吧",
            ico: "收",
            label: 6,
            bool: false,
          },
        ];
        this.remark = "";
      }
    },
  },
  data() {
    return {
      // 支付方式
      pay_ways: [
        {
          name: "收钱吧",
          ico: "收",
          label: 6,
          bool: false,
        },
      ],
      payWay: null,
      orderData: {},
      payVal: "",
      qrCodePayView: false,
      qrCodePayViewName: "收款中...",
      loading: false,
      buttonLoading: false,
      remark: "",
    };
  },
  methods: {
    // 计算商品采购价格
    // getGoodsStorePurchaseCalculateData() {
    //     this.loading = true;
    //     getGoodsStorePurchaseCalculate(this.goodsIdList)
    //         .then(({data}) => {
    //             if (data.code == 0) {
    //                 this.calculateData = data.data;
    //             } else {
    //                 this.$message.error(data.msg);
    //             }
    //             this.loading = false;
    //         })
    //         .catch(() => {
    //             this.loading = false;
    //         });
    // },
    close() {
      this.$emit("close");
    },
    enter() {
      if (!this.orderData.payWay) {
        return this.$message.error("请选择支付方式！");
      }
      if (!this.payVal) {
        return this.$message.error("请输入付款码！");
      }
      this.buttonLoading = true;
      if (this.type == 1) {
        addGoodsStorePurchase({
          payWay: this.orderData.payWay,
          goodsStorePurchaseDetailDTOList: this.calculateData.goodsIdList,
          remark: this.remark,
        })
          .then(({ data }) => {
            if (data.code == 0) {
              this.orderData.purchaseOrderId = data.data.purchaseId;
              this.pay();
            } else {
              this.$message.error(data.msg);
              this.buttonLoading = false;
            }
          })
          .catch(() => {
            this.buttonLoading = false;
          });
      } else {
        this.buttonLoading = true;
        this.pay();
      }
    },
    // 支付
    pay() {
      this.qrCodePayView = true;
      payGoodsStorePurchase({
        authCode: this.payVal,
        payWay: this.orderData.payWay,
        purchaseOrderId: this.orderData.purchaseOrderId,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            this.$message.success("支付成功！");
            this.queryPay({
              outTradeNo: data.data.outTradeNo,
              payWay: this.orderData.payWay,
              purchaseOrderId: this.orderData.purchaseOrderId,
            });
          } else {
            if (this.type == 1) {
              this.$emit("enter");
            }
            this.$message.error(data.data.msg);
          }
          this.buttonLoading = false;
          this.qrCodePayView = false;
        })
        .catch(() => {
          this.buttonLoading = false;
          this.qrCodePayView = false;
        });
    },
    // 查询是否支付
    queryPay(data) {
      getPayOrderQuery({
        ...data,
      }).then(({ data }) => {
        if (data.code != 0) {
          this.$message.error(data.msg);
        }
        this.$emit("enter");
      });
    },
    // 选择支付方式
    choosePayWay(data) {
      this.orderData.payWay = data.label;
      this.pay_ways = this.pay_ways.map((item) => {
        item.bool = false;
        if (data.label == item.label) {
          item.bool = true;
        }
        return item;
      });
      // 如果选择了余额或者洗后付或现金 不进行弹框 收钱吧弹框
      if (this.orderData.payWay === 6) {
        this.payWay = true;
        this.$nextTick(() => {
          this.$refs.pay.focus();
        });
      }
    },
    closePayWay() {
      this.payWay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

.content {
  align-items: flex-start !important;
  border-bottom: 1px solid #e8e8e8;
}

.settlement {
  padding: 20px;
  flex: 1;
}

.remark {
  width: 65px;
  color: #101010;
  text-align: right;
  padding-right: 5px;
}

.mode-payment {
  padding: 20px;
  flex: 1;
  min-height: 500px;
  border-left: 1px solid #e8e8e8;
  overflow: hidden;
  position: relative;

  .paymentView {
    overflow-x: hidden;
    overflow-y: auto;
    height: 340px;

    .paymentItem {
      position: relative;
      display: flex;
      padding: 15px 0 15px 25px;
      cursor: pointer;

      .iconView {
        margin-right: 28px;
        height: 42px;

        > .label-icon {
          display: inline-block;
          border-radius: 6px;
          width: 42px;
          height: 42px;
          line-height: 42px;
          font-size: 18px;
          font-family: PingFangSC-Medium;
          color: #fff;
          text-align: center;
          background: #1975ff;
        }
      }

      .textView {
        display: flex;
        flex: 1;
        font-size: 15px;
        font-family: PingFangSC-Medium;
        color: #333;
        line-height: 42px;

        > .label-name {
          flex: 2;
        }
      }
    }
  }

  .close-icon {
    font-size: 24px;
    color: #000;
  }

  #qrCodePayPopView {
    > .popView-bcView {
      background: transparent;
    }

    .qrCodePayView {
      background: #fff;
      min-height: 440px;

      > .contentView {
        width: 300px;

        > .amtView {
          line-height: 50px;
          font-size: 32px;
          color: #ff5e56;

          > span {
            font-size: 36px;
            margin-left: 5px;
          }
        }

        > .imgView {
          height: 46px;

          > img {
            width: 120px;
            height: 46px;
          }
        }

        > .qrCodeInputView {
          display: flex;
          padding: 10px 0;

          > .input-scanPayCode {
            flex: 1;
          }
        }

        > .tipView {
          line-height: 18px;
          font-size: 14px;
          color: #b0b0b0;
        }
      }
    }
  }
}

.bgc {
  background-color: #eaf4fe;
  border: 1px solid #1975ff;
  border-radius: 5px;
}

.border {
  border: 1px solid #fff;
}

.dialog-footer {
  text-align: center;
  margin: 10px 0;
}

.text1 {
  flex: 1;
  color: #101010;
}

.text2 {
  color: #898989;
  font-size: 16px;
}

.text3 {
  font-size: 24px;
  font-weight: 700;
  color: #282828;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
}

::v-deep .el-dialog__header {
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  align-items: center;
}

/*从右到左进入*/
.fadeInRight1 {
  width: 500px;
  min-height: 500px;
  background-color: #fff;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  animation: fadenum1 1s;
}

.fadeInRight2 {
  width: 500px;
  min-height: 500px;
  background-color: #fff;
  padding: 20px;
  position: absolute;
  top: 0;
  right: -1000px;
}

/*从右到左进入*/
@keyframes fadenum1 {
  0% {
    opacity: 0;
    right: -1000px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}
</style>
